<template>
    <div class="main">
        <h1>Create an account</h1>
        <Register :webinarRegister="false"></Register>
    </div>
</template>

<script>
import Register from './../components/RegisterForm'
export default {
    name : 'CreateAccount',
    components : {
        Register
    }
}
</script>

<style lang="scss" scoped>
    .main {
        width:1000px;
        max-width: 90%;
        margin:40px auto;
    }
</style>